export const getCountryFromCoordinates = async (lat, long) => {
    const apiKey = process.env.REACT_APP_OPENCAGE_GEOCODER_API;

    const url = `https://api.opencagedata.com/geocode/v1/json?q=${lat}+${long}&key=${apiKey}`;
    try {
        const response = await fetch(url);
        const data = await response.json();
        const countryName = data.results[0].components.country;
        const cityName = data.results[0].components.city ? data.results[0].components.city : data.results[0].components.state
        return cityName + ', ' + countryName;
    } catch (error) {
        console.error('Error fetching country:', error);
    }
};